import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const textImageCtaCarousel = document.querySelectorAll(
    '.text-image-cta__carousel'
  );

  if (0 < textImageCtaCarousel.length) {
    textImageCtaCarousel.forEach((carousel) => {
      new Swiper(carousel, {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          prevEl: carousel.querySelector('.swiper-button-prev'),
          nextEl: carousel.querySelector('.swiper-button-next'),
        },
        pagination: {
          el: carousel.querySelector('.swiper-pagination'),
          type: 'fraction',
        },
        mousewheel: {
          enabled: true,
          forceToAxis: true,
        },
        keyboard: true,
        breakpoints: {
          600: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1080: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        },
        a11y: {
          prevSlideMessage: _x(
            'See previous slide',
            'slider previous label',
            'vtx'
          ),
          nextSlideMessage: _x('See next slide', 'slider next label', 'vtx'),
          paginationBulletMessage: _x(
            'Go to slide {{index}}',
            'slide pagination label',
            'vtx'
          ),
          slideLabelMessage: _x(
            'Slide {{index}} of {{slidesLength}}',
            'slide label',
            'vtx'
          ),
        },
      });
    });
  }
});
